<section>
  <div class="logo">
    <img
      src="../../assets/img/atlas_logo.svg"
      height="38px"
      width="38px"
      alt=""
    />
    <span class="logo_name" [ngClass]="{ 'd-none': getSideBarState() }">
      Atlas</span
    >
  </div>
  <div class="side_content">
    <ul class="nav flex-column" id="nav_accordion">
      <ng-container *ngFor="let item of publicSideBar; let i = index">
        <!-- <ng-container *ngFor="let permission of loginData; let j = index;"> -->
          <!-- {{i}}, {{j}} -->
          <!-- <a
            *ngIf="
              permission === item.permissionStatus[1] ||
              permission === 'AccessAll'
            "
            href="#"
            [routerLink]="[item.url]"
            routerLinkActive="active"
            [class.disable-link]="item.disable"
          >
            <i class="fa-solid {{ item.icon }}"></i>

            <span>{{ item.name }}</span>
          </a> -->
          <li class="nav-item">
            <a
              class="nav-link"
              [attr.data-bs-toggle]="item?.dropdown ? 'collapse' : ''"
              [attr.data-bs-target]="item?.dropdown ? '#menu_item_' + i : ''"
              href="javascript:void(0)"
              [routerLink]="item?.url ? [item?.url] : null"
              routerLinkActive="active"
              [class.disable-link]="item?.disable"
              data-bs-toggle="tooltip" data-bs-placement="right" title="{{item?.description }}"
              
            >
              <i class="fa-solid {{ item?.icon }}"></i>

              <span>{{ item?.name }}</span>
              <svg
                *ngIf="item?.dropdown.length > 0"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M3.99995 3.99975C3.88595 3.99975 3.77245 3.96125 3.67995 3.88375L0.679955 1.38375C0.467955 1.20725 0.438955 0.891748 0.615955 0.679748C0.792455 0.467748 1.10745 0.439248 1.31995 0.615748L4.00545 2.85375L6.68645 0.696248C6.90145 0.523248 7.21645 0.557248 7.38945 0.772248C7.56245 0.987248 7.52845 1.30175 7.31345 1.47525L4.31345 3.88925C4.22195 3.96275 4.11095 3.99975 3.99995 3.99975Z"
                  fill=""
                />
              </svg>
            </a>
            <ul
              id="menu_item_{{ i }}"
              class="submenu collapse"
              data-bs-parent="#nav_accordion"
            >
              <ng-container *ngFor="let itemss of item?.dropdown">
                <li>
                  <a
                    class="nav-link"
                    href="javascript:void(0)"
                    [routerLink]="[itemss?.url]"
                    routerLinkActive="active"
                  >
                    <i class="bi small bi-caret-down-fill"></i>
                    <span>{{ itemss?.name }}</span></a
                  >
                </li>
              </ng-container>
            </ul>
          </li>
        <!-- </ng-container> -->
      </ng-container>
    </ul>
    <!-- <a ngIf="checker(arr, item)"
      *ngFor="let item of sidebar"
      href="#"
      [routerLink]="[item.url]"
      routerLinkActive="active"
      [class.disable-link]="item.disable"
    >
      <i class="fa-solid {{item.icon}}"></i>

      <span>{{item.name}}</span>
    </a> -->
  </div>
  <!-- <div class="logout">
    <a href="#" (click)="logout()">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.8999 8.16033C9.2099 4.56033 11.0599 3.09033 15.1099 3.09033H15.2399C19.7099 3.09033 21.4999 4.88033 21.4999 9.35033V15.8703C21.4999 20.3403 19.7099 22.1303 15.2399 22.1303H15.1099C11.0899 22.1303 9.2399 20.6803 8.9099 17.1403"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <g opacity="0.4">
          <path
            d="M14.9991 12.6001H3.61914"
            stroke=""
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.85 9.25049L2.5 12.6005L5.85 15.9505"
            stroke=""
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>

      <span>Log out</span>
    </a>
  </div> -->
</section>

<!-- <li class="nav-item">
  <a class="nav-link" data-bs-toggle="collapse" data-bs-target="#menu_item2" href="#"> More menus <i class="bi small bi-caret-down-fill"></i> </a>
  <ul id="menu_item2" class="submenu collapse" data-bs-parent="#nav_accordion">
    <li><a class="nav-link" href="#">Submenu item 4 </a></li>
      <li><a class="nav-link" href="#">Submenu item 5 </a></li>
      <li><a class="nav-link" href="#">Submenu item 6 </a></li>
      <li><a class="nav-link" href="#">Submenu item 6 </a></li>
  </ul>
</li> -->
