<section>
  <div class="mag-top-94">
    <!-- logo -->
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <div class="logo">
            <img
              src="../../assets/img/atlas_logo.svg"
              height="38px"
              width="38px"
              alt=""
            />
            <!-- <span class="logo_name"> Atlas</span> -->
          </div>
        </div>
        <div class="m-container">
          <div class="text-center content">
            <p class="m-0 p-0 one">
              Hello <strong>Admin</strong>
              <img
                src="../../assets/img/waving-hand-sign_1f44b 1 1.svg"
                alt=""
              />
            </p>
            <ng-container [ngSwitch]="values">
              <ng-template ngSwitchDefault>
                <p class="m-0 p-0 two">Kindly Log in to your account</p>
              </ng-template>
              <ng-template [ngSwitchCase]="0">
                <p class="m-0 p-0 two">
                  Kindly Log in to your Official Email Address.
                </p>
              </ng-template>
              <ng-template [ngSwitchCase]="1">
                <p class="m-0 p-0 two">
                  <span *ngIf="resend">
                    Please wait for
                    <span class="timing">{{ secondsLeft }}s</span> before
                    <br />
                    requesting for another OTP
                    <br />
                  </span>
                  <span *ngIf="!resend">
                    Kindly check your Official Email Address.
                    <br />
                  </span>
                  <span
                    class="green"
                    [class.disable]="resent"
                    (click)="onRest()"
                  >
                    Resend</span
                  >
                </p>
              </ng-template>
            </ng-container>
          </div>
          <ng-container *ngIf="!restPassword">
            <form [formGroup]="feedbackForm" *ngIf="!forgotPassword">
              <!-- <p>Form Status: {{ feedbackForm.status }}</p> -->
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input autocomplete="off"
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email Address"
                  formControlName="email"
                />
              </div>
              <div class=" position-relative">
                <label for="pass" class="form-label">Password</label>
                <input autocomplete="off"
                [type]="eyespass ? 'text' : 'password'"
                  class="form-control"
                  id="pass"
                  placeholder="Password"
                  formControlName="password"
                />
                <div class="eyes" (click)="onOpenPassword('eyesPass')">
                  <img src="../../../assets/img/{{ eyesDetailspass }}" alt="eyes"  width="20px"/>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center mt-2">
                <label
                  for="pass"
                  class="form-label pointer"
                  (click)="onForgotPassword()"
                  >forgot Password?</label
                >
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn btn-md w-100 bg-green"
                  (click)="onSubmit()"
                  [disabled]="feedbackForm.invalid"
                >
                  <span
                    class="lds-spinner lds-spinner--center pr-2"
                    *ngIf="spinner"
                  ></span>
                  <span *ngIf="!spinner">Log in</span>
                </button>
              </div>
            </form>
            <form action="" [formGroup]="feedresetForm" *ngIf="forgotPassword">
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input autocomplete="off"
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email Address"
                  formControlName="email2"
                />
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn btn-md w-100 bg-green"
                  (click)="onRest()"
                  [disabled]="feedresetForm.invalid"
                >
                  <span
                    class="lds-spinner lds-spinner--center pr-2"
                    *ngIf="spinner"
                  ></span>
                  <span *ngIf="!spinner">Reset Password</span>
                </button>
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn bg-transparent btn-md w-100"
                  (click)="onForgotPassword()"
                >
                  <span>Back</span>
                </button>
              </div>
            </form>
          </ng-container>
          <ng-container *ngIf="restPassword">
            <form action="" class="verify_input" *ngIf="!resetSuccessful">
              <div class="d-flex mb-3">
                <app-otp-input
                  #input="ngModel"
                  [(ngModel)]="data"
                  [ngModelOptions]="{ standalone: true }"
                />
                <!-- <b>Entered value:</b> {{ data }}
                <br />
                <b>Input valid:</b> {{ input.valid }} -->
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn btn-md w-100 bg-green"
                  (click)="resetCode()"
                  [disabled]="!handleValidate()"
                >
                  <span
                    class="lds-spinner lds-spinner--center pr-2"
                    *ngIf="spinner"
                  ></span>
                  <span *ngIf="!spinner">Reset Password</span>
                </button>
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn bg-transparent btn-md w-100"
                  (click)="onRestPassword()"
                >
                  <span>Back</span>
                </button>
              </div>
            </form>
            <form
              action=""
              [formGroup]="feedPasswordResetForm"
              *ngIf="resetSuccessful"
            >
              <!-- <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <input autocomplete="off"
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Email Address"
                  formControlName="email"
                  [disabled]="true"
                />
              </div> -->
              <div class="mb-3">
                <label for="pass" class="form-label">New Password</label>
                <input autocomplete="off"
                  [type]="eyes ? 'text' : 'password'"
                  id="enterPassword"
                  class="form-control"
                  formControlName="securityAnswer"
                  (keyup)="enterQuestion($event)"
                />
                <!-- <div class="eyes" (click)="onOpenPassword()">
                  <img src="../../../assets/img/{{ eyesDetails }}" alt="eyes" />
                </div> -->
              </div>
              <div class="mb-3">
                <label for="pass2" class="form-label">Confrim Password</label>
                <input autocomplete="off"
                  [type]="eyes ? 'text' : 'password'"
                  id="password1"
                  class="form-control"
                  formControlName="confirmSecurityAnswer"
                  (keyup)="onConfirm($event)"
                />
                <!-- <div class="eyes" (click)="onOpenPassword()">
                  <img src="../../../assets/img/{{ eyesDetails }}" alt="eyes" />
                </div> -->

                <small
                  class="alert"
                  *ngIf="confirm?.length > 0"
                  [class]="checkPassword ? 'active' : 'error'"
                >
                  <img
                    *ngIf="checkPassword"
                    src="../../assets/img/Atlas-Approved-icon.gif"
                    alt=""
                    width="18px"
                    height="18px"
                  />
                  {{
                    checkPassword ? "Password match." : "Passwords do not match"
                  }}
                </small>
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn btn-md w-100 bg-green"
                  (click)="onSubmitNewPass()"
                  [disabled]="setQuestion()"
                >
                  <span
                    class="lds-spinner lds-spinner--center pr-2"
                    *ngIf="spinner"
                  ></span>
                  <span *ngIf="!spinner">Change Password</span>
                </button>
              </div>
              <div class="mb-3 mt-3">
                <button
                  type="submit"
                  class="btn bg-transparent btn-md w-100"
                  (click)="onForgotPassword()"
                >
                  <span>Back</span>
                </button>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
