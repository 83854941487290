import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading: boolean = false;
  
  public loadingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  onOpenLoader() {
    this.loading = true;
    this.loadingSubject.next(this.loading)
  }
  onCloseLoader() {
    this.loading = false;
    this.loadingSubject.next(this.loading)
  }
}
