<app-toast></app-toast>

<router-outlet></router-outlet>

<div
  class="modal fade"
  [ngClass]="alertMsg ? 'show' : ''"
  [style]="alertMsg ? 'display: block;' : 'display: none'"
  id="staticBackdrop"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <div class="modal-header-header"> -->

        <button
          type="button"
          class="btn-close"
          (click)="onModal('')"
          aria-label="Close"
        ></button>
        <!-- </div> -->
      </div>
      <div class="modal-body">
        <div>
          <ngb-datepicker
            #dp
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            [navigation]="'select'"
            outsideDays="visible"
          >
          </ngb-datepicker>

          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>

          <hr />
          <div class="calendar_btn">
            <button
            class="btn btn-sm btn-light-green"
            (click)="onSend()"
          >
            Done
          </button>
          </div>
          <!-- <pre>From: {{ fromDate | json }} </pre>
          <pre>To: {{ toDate | json }} </pre> -->
        </div>
      </div>
    </div>
  </div>
</div>
