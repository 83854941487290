<div class="user-profile">
  <div class="notification">
    <img src="../../../assets/img/Setting-1.svg" alt="" />
    <img src="../../../assets/img/notification-bing.svg" alt="" />
  </div>
  <div class="btn-group">
    <button
      type="button"
      class="btn dropdown-toggle user-dropdown"
      data-bs-toggle="dropdown"
      aria-expanded=""
    >
      <img src="{{loginData.passport}}" width="36px" height="37px" alt="" style="border-radius: 50%;" />
      <div class="user_contant">
        <p>{{loginData.firstName}} {{loginData.lastName}}</p>
        <span>{{userRole}}</span>
      </div>
      <!-- <img src="../../../assets/img/dropdown.svg" alt="" /> -->
    </button>
    <ul class="dropdown-menu">
      <!-- <li><a class="dropdown-item" href="javascript:void(0)">Profile</a></li> -->
      <li><a class="dropdown-item" href="javascript:void(0)" [routerLink]="'/base/app/profile'">Settings</a></li>
      <li><hr class="dropdown-divider" /></li>
      <li><a class="dropdown-item" href="javascript:void(0)" style="color: #F15249;"  [routerLink]="'/login'" (click)="logout()">Log out</a></li>
    </ul>
  </div>
</div>
