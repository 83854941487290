import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'login',
    // loadChildren: () => import('../app/auth/auth.module')
    //   .then(m => m.AuthModule)
    component: AuthComponent
  },
  {
    path: 'base',
    loadChildren: () => import('../app/base/base.module')
      .then(m => m.BaseModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
