<section>
    <div class="toaster fade-in-image " 
    [class.active]="toastAction"
    [class.success]="success"
    [class.error]="error"
    [class.warning]="warning"
    >
        <div class="toast-content">
            <i class="fas fa-soild check"
            [class.fa-check]="success"
            [class.fa-xmark]="error"
            [class.fa-triangle-exclamation]="warning"
            ></i>
    
            <div class="message">
                <span class="text text-1">
                    {{resType}}
                </span>
                <span class="text text-2">
                    {{content}}
                </span>
            </div>
        </div>
        <i class="fa-solid fa-xmark close" (click)="onCancel()"></i>
    
        <div class="progress" [class.active]="toastAction"></div>
    </div>
</section>


