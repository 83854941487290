<section class="w-100 mb-5" *ngIf="name">
    <div class="">
      <div class="heading">
        <div class="user">
          <p>{{name}}</p>
          <p class="small">Last Login {{loginData.lastLoginDate | date : "short"}}</p>
        </div>
        <div class="pe-4">
          <app-profile-dropdown></app-profile-dropdown>
        </div>
      </div>
    </div>
  </section>