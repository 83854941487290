// import { ViewEncapsulation } from '@angular/compiler';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SidebarService } from '../services/sidebar.service';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { AdminService } from '../services/admin.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BillsService } from '../services/bills.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseComponent implements OnInit {
  loader: boolean = false;
  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private _auth: AuthService,
    private _admin: AdminService,
    private _account: AccountService,
    private _loading: LoadingService,
    private _billService: BillsService
  ) {
    _account.onAccountInformation();

    _loading.loadingSubject.asObservable().subscribe({
      next: (res) => {
        // //console.log(res);
        if (res !== null) {
          this.loader = res;
        }
      },
      error: (err) => {
        //console.log(err.error);
      },
    });

    let data2 = {
      startDate: '2023-01-01',
      endDate: '2023-06-21',
      accountType: null,
      // name: 'Peter',
    };

    let data3 = {
      startDate: '2023-01-01',
      endDate: '2023-06-21',
      accountType: null,
      name: 'Peter',
    };

    this._account.onGetUserChatAccount(data2);
    this._account.onGetUserChatWithNameAccount(data3);
    
    // this._admin.getBankSortCode();
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  ngOnInit() {
    // //console.log(this.getSideBarState())
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this._billService.onBillGraphSummary('Mtn')
  }
}
