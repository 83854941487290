import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AccountService } from './services/account.service';

import AOS from 'aos'; //AOS - 1
import { AdminService } from './services/admin.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from './services/toaster.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AuthService } from './services/auth.service';
import { AccountingService } from './services/accounting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'atlas-admin';

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  alertMsg: boolean = false;

  constructor(
    private router: Router,
    private _account: AccountService,
    private _admin: AdminService,
    private _accounting: AccountingService,
    private calendar: NgbCalendar,
    private toasterService: ToasterService,
    private bnIdle: BnNgIdleService,
    private _auth: AuthService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        // //console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        // //console.log(event.url);
        
        //console.log(event.url)
        if (
          event.url === '/base/app/account/overview/personal/tier-1' ||
          event.url === '/base/app/account/overview/personal/tier-2'
        ) {
          //console.log(true);
          this._account.navigateSubject.next(true);
        } else {
          this._account.navigateSubject.next(false);
        }
        if (
          event.url === '/base/app/settings/overview/create-user' ||
          event.url === '/base/app/account/overview/edit-user'
        ) {
          //console.log(true);
          this._admin.navigateSubject.next(true);
        } else {
          this._admin.navigateSubject.next(false);
        }
        if (
          event.url === '/base/app/accounting' ||
          event.url === '/base/app/accounting/overview/central-parameter' ||
          event.url === '/base/app/accounting/overview/module' ||
          event.url === '/base/app/accounting/overview/category' 
        ) {
          //console.log(event.url)
          //console.log(true);
          this._accounting.navigateSubject.next(true);
        } else {
          this._accounting.navigateSubject.next(false);
        }
      }

      if (event instanceof NavigationError) {
        // Hide progress spinner or progress bar

        // Present error to user
        //console.log(event.error);
      }

      
    });

    AOS.init(); //AOS - 2
    AOS.refresh(); //refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();

    this.toasterService.modalCalendarSubject
      .asObservable()
      .pipe()
      .subscribe((data: any) => {
        this.alertMsg = data;

        this.fromDate = this.calendar.getToday();
        this.toDate = this.calendar.getToday();
      });
  }
 

  ngOnInit(): void {
    // 120 => 2min
    this.bnIdle.startWatching(120).subscribe((res) => {
      if (res) {
        // //console.log('session expired');
        this._auth.logout();
        this.router.navigate(['login'])
        // window.location.reload();
        return;
      }
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    // this.toasterService.onCalendar(date);
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onModal(res) {
    this.toasterService.onCalendar(res);
  }

  onSend() {
    let date = {
      startDate: `${this.fromDate.year}-${String(this.fromDate.month).padStart(
        2,
        '0'
      )}-${String(this.fromDate.day).padStart(2, '0')}`,
      endDate: `${this.toDate.year}-${String(this.toDate.month).padStart(
        2,
        '0'
      )}-${String(this.toDate.day).padStart(2, '0')}`,
    };
    //console.log(date);
    this.toasterService.onCalendar(date);
  }
}
