import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecursiveAstVisitor } from '@angular/compiler';
import {  NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { BaseComponent } from './base/base.component';
import { baseUrl } from './classes/general';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AuthGuard } from './guard/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastComponent } from './components/toast/toast.component'
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';
// import { SwiperComponent } from './components/swiper/swiper.component';
import { SwiperModule } from 'swiper/angular';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TokeninterceptorService } from './services/tokeninterceptor.service';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { NumberSuffixPipe } from './pipes/number-suffix.pipe';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle';
import { OtpInputComponent } from "./components/otp-input/otp-input.component";
import { SelectComponent } from './components/select/select.component';
import { TableComponent } from './components/table/table.component';
// import { ClickOutsideDirective } from './directives/click-outside.directive';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        BaseComponent,
        SidebarComponent,
        NavbarComponent,
        ToastComponent,
        ProfileDropdownComponent,
        // ClickOutsideDirective,
    ],
    providers: [
        { provide: 'BaseURL', useValue: baseUrl },
        { provide: HTTP_INTERCEPTORS, useClass: TokeninterceptorService, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        AuthGuard,
        BnNgIdleService,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        SwiperModule,
        NgbModule,
        NgbDatepickerModule,
        OtpInputComponent
    ]
})
export class AppModule { }
