import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  public toasterSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public modalSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public modalDeleteSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public modalRoleSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public modalCalendarSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public calendarResSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  
  modal: boolean = false;
  calendar: boolean = false;
  constructor() { }


  alert(type: any, content: any) {
    let payload = {
      type,
      content
    }

    this.toasterSubject.next(payload)
  }

  onModal(data) {
    this.modal = !this.modal;

    if (data == 'delete') {
      this.modalDeleteSubject.next(this.modal)
      return
    }
    if (data == 'role') {
      this.modalRoleSubject.next(this.modal)
      return
    }
    this.modalSubject.next(this.modal)

  }

  onCalendar(data: any) {
    this.calendar = !this.calendar;
    this.modalCalendarSubject.next(this.calendar);
    if (data) {
      this.calendarResSubject.next(data)
      return;
    }
  }


}
