<section class="bg">
  <div class="sidebar" [ngClass]="{'nav-collapsed' : getSideBarState()}">
    <div class="navigate d-md-none d-block" (click)="toggleSidebar()" >
      <i class="fa-solid fa-bars-staggered"></i>
    </div>
    <app-sidebar></app-sidebar>
  </div>
  <div class="main">
    <div class="content">
      <app-navbar></app-navbar>
        <router-outlet></router-outlet>
    </div>
  </div>
</section>

<div class="loading" *ngIf="loader">
  <div class='uil-ring-css' style='transform:scale(0.79);'>
    <div></div>
  </div>
</div>


