import { Injectable } from '@angular/core';
import { baseUrl, httpAuthOptions } from '../classes/general';
import {
  HttpHeaders,
  HttpClient,
  HttpEventType,
  HttpProgressEvent,
  HttpEvent,
} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginData: any;
  public loginSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public titleSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public optionSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService
    ) {
    this.loginSubject.asObservable().subscribe({
      next: (res) => {
        // //console.log(res);
        this.loginData = res?.result?.token;
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }

  public getIPAddress() {
    return this._http.get('http://api.ipify.org/?format=json');
  }

  onLogin(payload: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload)
    }
    // //console.log(encryptedPayload)
    return this._http
      .post<any>(`${baseUrl}/api/adminuser/login`, encryptedPayload, httpAuthOptions)
      .pipe();
  }

  onResetPassword(email: any) {
    // let encryptedPayload = {
    //   data: this._encryptDecrypt.encrypt(payload)
    // }
    // //console.log(encryptedPayload)
    return this._http
      .get<any>(`${baseUrl}/api/adminUser/admin/sendOtp?email=${email}`)
      .pipe();
  }

  

  onConfrimOTP(payload: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload)
    }
    // //console.log(encryptedPayload)
    return this._http
      .post<any>(`${baseUrl}/api/AdminUser/admin/confirmOtp`, encryptedPayload)
      .pipe();
  }

  onReset(payload: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(payload)
    }
    // //console.log(encryptedPayload)
    return this._http
      .post<any>(`${baseUrl}/api/AdminUser/admin/resetPassword`, encryptedPayload)
      .pipe();
  }


  loggedIn() {
    // return true
    // //console.log(this.loginData);
    return !!this.loginData;
  }

  logout() {
    this.loginSubject.next(null);
    this.loginData = null;
  }

  navTitle(data: string) {
    this.titleSubject.next(data);
  }

  optionShower(data: boolean) {
    this.optionSubject.next(data);
  }
}
