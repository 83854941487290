import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { baseUrl } from '../classes/general';
import { AuthService } from './auth.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class BillsService {
  public airtimeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public billSummarySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public billGraphSummarySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private _http: HttpClient,
    private _Auth: AuthService,
    private _loading: LoadingService,
    private _encryptDecrypt: EncryptDecryptService
  ) { }

  onAirtime(page?, pageSize?, searchValue?, from?, to?, billStatus?, billType?, transferStatus?, reversalStatus?) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/bills/all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (page || pageSize || searchValue || from || to || billStatus || billType || transferStatus || reversalStatus) {
      url = `${baseUrl}/api/bills/all?`;
    }
    if (page) {
      if (first) {
        url = `${url}page=${page}`;
        first = false;
      } else url = `${url}&page=${page}`;
    }
    if (pageSize) {
      if (first) {
        url = `${url}pageSize=${pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${pageSize}`;
    }
    if (searchValue) {
      if (first) {
        url = `${url}searchValue=${searchValue}`;
        first = false;
      } else url = `${url}&searchValue=${searchValue}`;
    }
    if (from) {
      if (first) {
        url = `${url}from=${from}`;
        first = false;
      } else url = `${url}&from=${from}`;
    }
    if (to) {
      if (first) {
        url = `${url}to=${to}`;
        first = false;
      } else url = `${url}&to=${to}`;
    }
    if (billStatus) {
      if (first) {
        url = `${url}billStatus=${billStatus}`;
        first = false;
      } else url = `${url}&billStatus=${billStatus}`;
    }
    if (billType) {
      if (first) {
        url = `${url}billType=${billType}`;
        first = false;
      } else url = `${url}&billType=${billType}`;
    }
    if (transferStatus) {
      if (first) {
        url = `${url}transferStatus=${transferStatus}`;
        first = false;
      } else url = `${url}&transferStatus=${transferStatus}`;
    }
    if (reversalStatus) {
      if (first) {
        url = `${url}reversalStatus=${reversalStatus}`;
        first = false;
      } else url = `${url}&reversalStatus=${reversalStatus}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.airtimeSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onBillSummary( from?, to?) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/bills/summary`;
    let first = true;
    //   //console.log(reservationId, category)
    if (from || to) {
      url = `${baseUrl}/api/bills/summary?`;
    }
    if (from) {
      if (first) {
        url = `${url}from=${from}`;
        first = false;
      } else url = `${url}&from=${from}`;
    }
    if (to) {
      if (first) {
        url = `${url}to=${to}`;
        first = false;
      } else url = `${url}&to=${to}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.billSummarySubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onBillGraphSummary( networkShortName?) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/bills/graph/summary`;
    let first = true;
    //   //console.log(reservationId, category)
    if (networkShortName) {
      url = `${baseUrl}/api/bills/graph/summary?`;
    }
    if (networkShortName) {
      if (first) {
        url = `${url}networkShortName=${networkShortName}`;
        first = false;
      } else url = `${url}&networkShortName=${networkShortName}`;
    }

    //console.log(url)
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.billGraphSummarySubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  
}
