import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { baseUrl } from '../classes/general';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public navigateSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public accountInformationSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  public accountSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public onGetAccountSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  public accountChatSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  public accountChatWithNameSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);

  public accountAccountsToValidateSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>([]);

  public loginDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public onApproveUpgradeSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});

  // public navigateSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public vaultRateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public onAllPermissionSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});

  public onFetchAccountDetailsSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});

  public auditLogSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public onTransHistorySubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public onTransHistoriesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public onDisputeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public onDisputeDetailsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public onAccountStatementSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  
  loginDatass: any;

  constructor(
    private _http: HttpClient,
    private _Auth: AuthService,
    private _loading: LoadingService,
    private _encryptDecrypt: EncryptDecryptService
  ) {
    this._Auth.loginSubject.asObservable().subscribe({
      next: (res) => {
        // //console.log(res);
        // this.loginDatass = res?.result.token;
        this.loginDataSubject.next(res?.result?.token);

        this.loginDatass = new HttpHeaders().set(
          'Authorization',
          'bearer ' + res?.result?.token
        );
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.loginDataSubject.value}`,
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // 'APPKey': 'a96834df-0821-4956-a0f2-2df3c23bde25',
    }),
  };

  

  onAccountInformation() {
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${baseUrl}/api/admin/GetAtlasAccountInformation`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.accountInformationSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetAccount(data?) {
    let page = data?.page,
      pageSize = data?.pageSize,
      searchValue = data?.searchValue,
      from = data?.from,
      to = data?.to,
      accountType = data?.accountType;

    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/AdminAccountModule/all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (
      page ||
      pageSize ||
      searchValue ||
      from ||
      to ||
      accountType
    ) {
      url = `${baseUrl}/api/AdminAccountModule/all?`;
    }
    if (page) {
      if (first) {
        url = `${url}page=${page}`;
        first = false;
      } else url = `${url}&page=${page}`;
    }
    if (pageSize) {
      if (first) {
        url = `${url}pageSize=${pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${pageSize}`;
    }
    if (searchValue) {
      if (first) {
        url = `${url}searchValue=${searchValue}`;
        first = false;
      } else url = `${url}&searchValue=${searchValue}`;
    }
    if (from) {
      if (first) {
        url = `${url}from=${from}`;
        first = false;
      } else url = `${url}&from=${from}`;
    }
    if (to) {
      if (first) {
        url = `${url}to=${to}`;
        first = false;
      } else url = `${url}&to=${to}`;
    }
    if (accountType) {
      if (first) {
        url = `${url}accountType=${accountType}`;
        first = false;
      } else url = `${url}&accountType=${accountType}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onGetAccountSubject.next(result);
        },
        error: (err) => {
          this.onGetAccountSubject.next({});
          // //console.log(err.error);
        },
      });
  }

  onGetUserAccount(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    // //console.log(encryptedPayload)
    this._loading.onOpenLoader();
    return this._http
      .post<any>(`${baseUrl}/api/admin/GetUserAccounts`, encryptedPayload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.accountSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetUserChatAccount(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    // //console.log(encryptedPayload)
    this._loading.onOpenLoader();
    return this._http
      .post<any>(`${baseUrl}/api/Admin/GetInflowOutflowAsync`, encryptedPayload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.accountChatSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetUserChatWithNameAccount(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(`${baseUrl}/api/Admin/GetInflowOutflowAsync`, encryptedPayload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.accountChatWithNameSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onAllPermission() {
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${baseUrl}/api/permission/all`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          //console.log('result', result);
          //console.log(result?.result?.splice(0, 2));
          this.onAllPermissionSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onAuditLog(page, pageSize, searchValue, from, to) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/auditlog/alls`;
    let first = true;
    //   //console.log(reservationId, category)
    if (page || pageSize || searchValue || from || to) {
      url = `${baseUrl}/api/auditlog/all?`;
    }
    if (page) {
      if (first) {
        url = `${url}page=${page}`;
        first = false;
      } else url = `${url}&page=${page}`;
    }
    if (pageSize) {
      if (first) {
        url = `${url}pageSize=${pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${pageSize}`;
    }
    if (searchValue) {
      if (first) {
        url = `${url}searchValue=${searchValue}`;
        first = false;
      } else url = `${url}&searchValue=${searchValue}`;
    }
    if (from) {
      if (first) {
        url = `${url}from=${from}`;
        first = false;
      } else url = `${url}&from=${from}`;
    }
    if (to) {
      if (first) {
        url = `${url}to=${to}`;
        first = false;
      } else url = `${url}&to=${to}`;
    }
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.auditLogSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetTransHistories(data) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(
        `${baseUrl}/api/transactions/FetchAdminTransactionHistory`,
        encryptedPayload
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onTransHistoriesSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onGetTransHistory(data) {
    let page = data.page,
      pageSize = data.pageSize,
      search = data.search,
      from = data.startDate,
      to = data.endDate,
      transactionCategory = data.transactionCategory,
      transactionType = data.transactionType,
      paymentChannel = data.paymentChannel,
      transactionStatus = data.transactionStatus,
      accountType = data.accountType;

    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/transactions/for-admin/all`;
    let first = true;
    //   //console.log(reservationId, category)
    if (
      page ||
      pageSize ||
      search ||
      from ||
      to ||
      transactionCategory ||
      transactionType ||
      paymentChannel ||
      transactionStatus ||
      accountType
    ) {
      url = `${baseUrl}/api/transactions/for-admin/all?`;
    }
    if (page) {
      if (first) {
        url = `${url}pageNumber=${page}`;
        first = false;
      } else url = `${url}&pageNumber=${page}`;
    }
    if (pageSize) {
      if (first) {
        url = `${url}pageSize=${pageSize}`;
        first = false;
      } else url = `${url}&pageSize=${pageSize}`;
    }
    if (search) {
      if (first) {
        url = `${url}searchValue=${search}`;
        first = false;
      } else url = `${url}&searchValue=${search}`;
    }
    if (from) {
      if (first) {
        url = `${url}startDate=${from}`;
        first = false;
      } else url = `${url}&startDate=${from}`;
    }
    if (to) {
      if (first) {
        url = `${url}endDate=${to}`;
        first = false;
      } else url = `${url}&endDate=${to}`;
    }
    if (transactionCategory) {
      if (first) {
        url = `${url}transactionCategory=${transactionCategory}`;
        first = false;
      } else url = `${url}&transactionCategory=${transactionCategory}`;
    }
    if (transactionType) {
      if (first) {
        url = `${url}transactionType=${transactionType}`;
        first = false;
      } else url = `${url}&transactionType=${transactionType}`;
    }
    if (paymentChannel) {
      if (first) {
        url = `${url}paymentChannel=${paymentChannel}`;
        first = false;
      } else url = `${url}&paymentChannel=${paymentChannel}`;
    }
    if (transactionStatus) {
      if (first) {
        url = `${url}transactionStatus=${transactionStatus}`;
        first = false;
      } else url = `${url}&transactionStatus=${transactionStatus}`;
    }
    if (accountType) {
      if (first) {
        url = `${url}accountType=${accountType}`;
        first = false;
      } else url = `${url}&accountType=${accountType}`;
    }

    //console.log(url);
    this._loading.onOpenLoader();
    return this._http
      .get<any>(url)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onTransHistorySubject.next(result);
        },
        error: (err) => {
          this.onTransHistorySubject.next({});
          // //console.log(err.error);
        },
      });
  }

  onGetDispute(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(`${baseUrl}/api/Admin/FetchDispute`, encryptedPayload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onDisputeSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onGetDisputeDetails(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(`${baseUrl}/api/Admin/FetchDisputeDetail`, encryptedPayload)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onDisputeDetailsSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
  onPassDisputeStatus(data: any) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/Admin/Update/status`;
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    // //console.log(url)
    return this._http.post<any>(url, encryptedPayload).pipe(
      finalize(() => {
        this._loading.onCloseLoader();
      })
    );
  }
  onSendDisputeMsg(data: any) {
    this._loading.onOpenLoader();
    let url = `${baseUrl}/api/Admin/SendMessage`;
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    // //console.log(url)
    return this._http.post<any>(url, encryptedPayload).pipe(
      finalize(() => {
        this._loading.onCloseLoader();
      })
    );
  }

  onFetchAccountsToValidate(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(
        `${baseUrl}/api/Admin/FetchAccountsToValidate`,
        encryptedPayload
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.accountAccountsToValidateSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onFetchAccountDetails(data: any) {
    this.onFetchAccountDetailsSubject.next({});
    this._loading.onOpenLoader();
    return this._http
      .get<any>(`${baseUrl}/api/Admin/FetchAccountDetails/${data}`)
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onFetchAccountDetailsSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }

  onApproveUpgrade(data: any) {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(
        `${baseUrl}/api/AccountSetting/ApproveUpgrade`,
        encryptedPayload
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      );
  }

  onFetchAccountStatement(data: any) {
    this.onAccountStatementSubject.next({});
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(data),
    };
    this._loading.onOpenLoader();
    return this._http
      .post<any>(
        `${baseUrl}/api/transactions/account-statement-for-admin`,
        encryptedPayload
      )
      .pipe(
        finalize(() => {
          this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // //console.log(res);
          let result = this._encryptDecrypt.decrypt(res.data);
          this.onAccountStatementSubject.next(result);
        },
        error: (err) => {
          // //console.log(err.error);
        },
      });
  }
}
