import { Component, ViewEncapsulation, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Subject, finalize, map, take, takeUntil, timer } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from '../services/toaster.service';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  // standalone: true,
  // encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit, OnDestroy {
  spinner: boolean = false;
  feedbackForm: FormGroup;
  feedresetForm: FormGroup;
  feedPasswordResetForm: FormGroup;
  ipAddress: string;
  forgotPassword: boolean = false;
  restPassword: boolean = false;
  resetSuccessful: boolean = false;
  data = '';
  secondsLeft: any;
  referenceCode: any;
  sentTo: any;
  values: any;
  resend: boolean = false;
  resent: boolean = false;

  code: any;
  userID: any;

  email: any;

  eyes: boolean = false;
  eyespass: boolean = false;
  checkPassword: boolean = false;
  pass: any;
  confirm: any;
  eyesDetails: any;
  eyesDetailspass: any;

  private unsubscribe$ = new Subject<void>();

	_activatedRoute = inject(ActivatedRoute);

	returnUrl: any = this._activatedRoute.snapshot.queryParams['returnUrl'] || 'base/app/overview';
  formattedReturnUrl = Array.isArray(this.returnUrl) ? this.returnUrl.join('/') : this.returnUrl;
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _fb: FormBuilder,
    private _toaster: ToasterService,
    private _encryptDecrypt: EncryptDecryptService
  ) {
    this.initForm();
  }
  ngOnInit(): void {
    this._auth.logout();
    this.eyesDetails = 'invisible-eyes.svg';
    this.eyesDetailspass = 'invisible-eyes.svg';
    // //console.log(this._encryptDecrypt.decrypt('I+7L5ZuvdzHO28i1hM88dledN+gtPWJaKZ8K9SW4IRXs0tdtCUQpBNHWvWLKOW2b'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  initForm() {
    this.feedbackForm = this._fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: ['', Validators.required],
    });

    this.feedresetForm = this._fb.group({
      email2: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });

    this.feedPasswordResetForm = this._fb.group({
      email2: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      pass: ['', Validators.required],
      pass2: ['', Validators.required],
    });
  }

  getIP() {
    this._auth.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  onSubmit() {
    // this._router.navigate(['passwordverify'], { relativeTo: this._route.parent })
    this.spinner = true;
    let payload = {
      email: this.feedbackForm.value.email, //'alabideborah973@gmail.com'
      password: this.feedbackForm.value.password, //'Pelumi@2023'
      fireBaseToken: '123456789',
      ipAddress: this.ipAddress,
    };
    this._auth
      .onLogin(payload)
      .pipe(
        finalize(() => {
          this.spinner = false;
        })
      )
      .subscribe({
        next: (res) => {
          let result = this._encryptDecrypt.decrypt(res.data);

          if (result.hasError === false) {
            // console.log(result);
            this._auth.loginSubject.next(result);
            this._router.navigateByUrl(this.returnUrl)
				// this._router.navigate(this.returnUrl, { queryParams: { returnUrl: this.formattedReturnUrl } })
            // this._router.navigate(['base', 'app', 'overview']);
          }
        },
        error: (err) => {
          let result = this._encryptDecrypt.decrypt(err.error.data);
          this._toaster.alert('error', result.message);
        },
      });
  }

  onRest() {
    this.spinner = true;
    this.email = this.feedresetForm.value.email2;
    let payload = {
      userData: this.feedresetForm.value.email2,
    };
    // console.log(payload);
    this._auth
      .onResetPassword(payload.userData)
      .pipe(
        finalize(() => {
          this.spinner = false;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (res) => {
          // return
          let result = this._encryptDecrypt.decrypt(res.data);
          console.log(result);

          if (result.result.status === 'Sent') {
            this._toaster.alert('success', result.message);
            this.referenceCode = result.result.referenceCode;
            this.sentTo = result.result.sentTo;
            this.restPassword = true;
            this.spinner = false;
            this.values = 1;
            this.onRestTimer();
            return;
          }
        },
        error: (err) => {
          // console.log(err);
          // return
          // let result = this._encryptDecrypt.decrypt(err.error.data);
          this._toaster.alert('error', err.error.message);
        },
      });
  }

  onRestTimer() {
    this.resend = true;
    this.resent = true;
    const countdown$ = timer(0, 1000).pipe(
      take(31),
      map((secondsElapsed) => 31 - secondsElapsed)
    );

    countdown$.subscribe((secondsLeft) => {
      this.secondsLeft = secondsLeft - 1;

      if (this.secondsLeft === 0) {
        this.resent = false;
      } else {
        this.resent = true;
      }
    });
  }

  onForgotPassword() {
    this.values = 0;
    this.forgotPassword = !this.forgotPassword;
    this.restPassword = false;
  }
  onRestPassword() {
    this.restPassword = !this.restPassword;
  }

  resetCode() {
    let payload = {
      OTP: this.data,
      ReferenceCode: this.referenceCode,
    };
    // console.log("payload ==> ", payload);
    this._auth
      .onConfrimOTP(payload)
      .pipe(
        finalize(() => {
          this.spinner = false;
        })
      )
      .subscribe({
        next: (res) => {
          // return
          let result = this._encryptDecrypt.decrypt(res.data);
          console.log(result);
          if (result.result.code) {
            this._toaster.alert('success', result.message);
            this.code = result.result.code;
            this.userID = result.result.userId;
            this.values = 2;
            this.resetSuccessful = true;
            return;
          }
        },
        error: (err) => {
          // console.log(err);
          // return
          let result = this._encryptDecrypt.decrypt(err.error.data);
          this._toaster.alert('error', result.message);
        },
      });
  }

  enterQuestion(e: any) {
    this.pass = e.target.value;

    if (this.pass == this.confirm) {
      //process the correct data
      this.checkPassword = true;
      // console.log('true>>>', 'true');
      return;
    }
    this.checkPassword = false;
  }

  onConfirm(e: any) {
    this.confirm = e.target.value;
    // console.log(this.pass, this.confirm);

    if (this.pass == this.confirm) {
      //process the correct data
      this.checkPassword = true;
      // console.log('true>>>', 'true');
      return;
    }
    this.checkPassword = false;
    // return
  }

  onOpenPassword(d: any) {
    if (d === 'eyesPass') {
      this.eyespass = !this.eyespass;
      if (this.eyespass) {
        this.eyesDetailspass = 'visible-eyes.svg';
        return;
      }
      this.eyesDetailspass = 'invisible-eyes.svg';
    }

    this.eyes = !this.eyes;
    if (this.eyes) {
      this.eyesDetails = 'visible-eyes.svg';
      return;
    }
    this.eyesDetails = 'invisible-eyes.svg';
  }

  setQuestion() {
    if (this.pass && this.confirm && this.pass == this.confirm) {
      return false;
    }
    return true;
  }

  onSubmitNewPass() {
    let payload = {
      ReferenceCode: this.code,
      UserData: this.email, /// userEmailAddress
      Password: this.confirm,
    };
    console.log('payload ==> ', payload);
    this._auth
      .onReset(payload)
      .pipe(
        finalize(() => {
          this.spinner = false;
        })
      )
      .subscribe({
        next: (res) => {
          // return
          let result = this._encryptDecrypt.decrypt(res.data);
          console.log(result);
          this._toaster.alert('success', result.message);
          this.onForgotPassword();
          return;
          if (result.result.code) {
            this._toaster.alert('success', result.message);
            this.code = result.result.code;
            this.userID = result.result.userId;
            this.values = 2;
            this.resetSuccessful = true;
            return;
          }
        },
        error: (err) => {
          // console.log(err);
          // return
          let result = this._encryptDecrypt.decrypt(err.error.data);
          this._toaster.alert('error', result.message);
        },
      });
  }
  handleValidate() {
    if (this.data.length === 4) {
      return true;
    }
    return false;
  }
}
