import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize } from 'rxjs';
import { baseUrl } from '../classes/general';
import { AuthService } from './auth.service';
import { LoadingService } from './loading.service';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public navigateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public vaultRateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public onAllPermissionSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  public loginDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  public auditLogSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public onBankSortCodeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private _http: HttpClient,
    private _Auth: AuthService,
    private _loading: LoadingService,
    private _encryptDecrypt: EncryptDecryptService
  ) {
    this._Auth.loginSubject.asObservable().subscribe({
      next: (res) => {
        // //console.log(res);
        this.loginDataSubject.next(res?.result?.token);
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }

  // getBankSortCode() {
  //   this._loading.onOpenLoader();
  //   return this._http
  //     .get<any>(
  //       `${baseUrl}/api/fundtransfer/GetTransferDependencies/8880000001`
  //     )
  //     .pipe(
  //       finalize(() => {
  //         this._loading.onCloseLoader();
  //       })
  //     )
  //     .subscribe({
  //       next: (res) => {
  //         // //console.log(res);
  //         let result = this._encryptDecrypt.decrypt(res.data)
  //         this.onBankSortCodeSubject.next(result);
  //       },
  //       error: (err) => {
  //         // //console.log(err.error);
  //       },
  //     });
  // }
}
