import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent {
  loginData: any;
  userRole: any;

  constructor(private _authService: AuthService) {
    _authService.loginSubject.asObservable().subscribe((res: any) => {
      // console.log(res?.result);
      this.loginData = res?.result;
      this.OnUserRole()
    });
  }

  OnUserRole() {
    
    if (this.loginData?.userType === 1) {
      this.userRole = 'Super admin'
      return
    }
  }

  logout() {
    this._authService.logout();
  }
}
