import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor {
  public loginDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

loginDatass: any;
  constructor(

    private _Auth: AuthService,
  ) {
    this._Auth.loginSubject.asObservable().subscribe({
      next: (res) => {
        // //console.log(res);
        // this.loginDatass = res.result.token;
        this.loginDataSubject.next(res?.result?.token);

        this.loginDatass = res?.result?.token;
      },
      error: (err) => {
        // //console.log(err.error);
      },
    });
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let tokenHeadFer = req.clone({
      setHeaders: {
      Authorization:'bearer '+ this.loginDatass
      }
      });

    return next.handle(tokenHeadFer)
  }
}
